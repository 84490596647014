import {NavLink} from "react-router-dom";

const Sidebar = () => {
    return (
        <aside className="main-sidebar sidebar-dark-primary elevation-4">
            <a href="javascript:void(0)" className="brand-link text-center">
                <span className="brand-text font-weight-light">Moving Boxes</span>
            </a>

            <div className="sidebar">
                <nav className="mt-2">
                    <ul className="nav nav-pills nav-sidebar flex-column p-2" data-widget="treeview" role="menu"
                        data-accordion="false">
                        <li className="nav-item menu-open">
                            <NavLink to={'/day'} className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}>
                                <i className="nav-icon fas fa-file"></i>
                                <p>
                                    Day List
                                </p>
                            </NavLink>
                        </li>
                        <li className="nav-item menu-open">
                            <NavLink to={'/month'} className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}>
                                <i className="nav-icon fas fa-file"></i>
                                <p>
                                    Month List
                                </p>
                            </NavLink>
                        </li>

                        <li className="nav-item menu-open">
                            <NavLink to={'/blocked-dates'} className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}>
                                <i className="nav-icon fas fa-file"></i>
                                <p>
                                    Blocked Dates
                                </p>
                            </NavLink>
                        </li>
                    </ul>
                </nav>
            </div>
        </aside>
    )
}

export default Sidebar;