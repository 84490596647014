import {Routes, Route, useNavigate, Navigate} from "react-router-dom";
import DashboardLayout from "../views/layout/DashboardLayout";
import Login from "../views/Login";
import React from "react";
import {PrivateRoute} from "./PrivateRoute";
import {isAuthenticated} from "../services/AuthenticationService";
import NoTransport from "../views/NoTransport";
import Search from "../views/Search";
import Reports from "../views/Reports";
import Companies from "../views/Companies";
import EditCompany from "../components/companies/EditCompany";
import CreateCompany from "../components/companies/CreateCompany";
import PricesList from "../components/price/PricesList";
import CreatePrice from "../components/price/CreatePrice";
import EditPrice from "../components/price/EditPrice";
import CitiesList from "../components/cities/CitiesList";
import EditCity from "../components/cities/EditCity";
import CreateCity from "../components/cities/CreateCity";
import BlockedDatesList from "../components/BlockedDates/BlockedDatesList";
import EditBlockedDates from "../components/BlockedDates/EditBlockedDates";
import CreateBlockedDates from "../components/BlockedDates/CreateBlockedDates";
import DayList from "../components/bookings/DayList";
import MonthList from "../components/bookings/MonthList";
import EditBooking from "../components/bookings/EditBooking";
import CreateBooking from "../components/bookings/CreateBooking";
import ForgotPassword from "../views/ForgotPassword";
import ResetPassword from "../views/ResetPassword";
import ResetPasswordLinkExpired from "../views/ResetPasswordLinkExpired";

const RouteList = () => {
    return (
        <Routes>
            <Route path="/"  element={
                <PrivateRoute isAuthenticated={isAuthenticated()}>
                    <DashboardLayout />
                </PrivateRoute>
            }>
                <Route index element={<DayList />}/>
                <Route path="/day" element={<DayList/>}/>
                <Route path="/month"  element={<MonthList />}/>

                {/* Blocked Dates */}
                <Route path="/blocked-dates" element={<BlockedDatesList />} />
                <Route path="/blocked-dates/:id" element={<EditBlockedDates />} />
                <Route path="/blocked-dates/create" element={<CreateBlockedDates />} />

                {/* Bookings */}
                <Route path="/bookings/:id" element={<EditBooking />} />
                <Route path="/bookings/create" element={<CreateBooking />} />
            </Route>

            <Route path="/login"  element={
                isAuthenticated() ? <Navigate to="/" replace={true} /> : <Login />
            }/>
            {/* Forgot Password */}
            <Route path="/forgot-password"  element={<ForgotPassword />}/>
            {/* Forgot Password */}
            <Route path="/reset-password/:token"  element={<ResetPassword />}/>
            {/* Reset Password Link Expired*/}
            <Route path="/reset-password/expired"  element={<ResetPasswordLinkExpired />}/>
        </Routes>
    )
}

export default RouteList;